import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  query,
  where,
  getDoc,
  runTransaction,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { message } from "antd";
import { Player } from "../../types";

interface PlayerState {
  players: Player[];
  player: Player | null;
  loading: boolean;
  error: string | null;
}

// Initial State
const initialState: PlayerState = {
  players: [],
  player: null,
  loading: false,
  error: null,
};

// Async Thunk to Add Player with Running Number
export const addPlayer = createAsyncThunk(
  "players/addPlayer",
  async (player: Omit<Player, "id">, { rejectWithValue }) => {
    try {
      const counterRef = doc(db, "counters", "players");

      // Use Firestore transaction to ensure atomicity
      const newPlayerId = await runTransaction(db, async (transaction) => {
        const counterDoc = await transaction.get(counterRef);

        if (!counterDoc.exists()) {
          // Initialize the counter document if it doesn't exist
          transaction.set(counterRef, { lastId: 1 });
          return 1;
        }

        const currentLastId = counterDoc.data().lastId || 0;
        const nextId = currentLastId + 1;

        // Update the counter document with the new lastId
        transaction.update(counterRef, { lastId: nextId });

        return nextId;
      });

      // Add the new player with the generated ID
      const newPlayer = { ...player, id: newPlayerId.toString() };
      const docRef = doc(db, "players", newPlayerId.toString());
      await setDoc(docRef, newPlayer);

      message.success("Player added successfully!");
      return newPlayer;
    } catch (error) {
      message.error(`Error: ${error || "Failed to add player."}`);
      return rejectWithValue("Failed to add player");
    }
  }
);

// Async Thunk to Update Player in Firestore
export const updatePlayer = createAsyncThunk(
  "players/updatePlayer",
  async (player: Player, { rejectWithValue }) => {
    try {
      if (!player.id) {
        throw new Error("Player ID is required for updating.");
      }
      await setDoc(doc(db, "players", player.id), player, { merge: true });
      message.success("Player updated successfully!");
      return player;
    } catch (error) {
      message.error(`Error: ${error || "Failed to update player."}`);
      return rejectWithValue("Failed to update player");
    }
  }
);

// Async Thunk to Delete Player from Firestore
export const deletePlayer = createAsyncThunk(
  "players/deletePlayer",
  async (id: string, { rejectWithValue }) => {
    try {
      await setDoc(
        doc(db, "players", id),
        { isDeleted: true },
        { merge: true }
      );
      message.success("Player deleted successfully!");
      return id;
    } catch (error) {
      message.error(`Error: ${error || "Failed to delete player."}`);
      return rejectWithValue("Failed to delete player");
    }
  }
);

// Async Thunk to Fetch Players from Firestore
export const fetchPlayers = createAsyncThunk(
  "players/fetchPlayers",
  async (_, { rejectWithValue }) => {
    try {
      const playersRef = collection(db, "players");
      const q = query(playersRef, where("isDeleted", "==", false));
      const querySnapshot = await getDocs(q);
      const players: Player[] = [];
      querySnapshot.forEach((doc) => {
        players.push({ id: doc.id, ...doc.data() } as Player);
      });

      players.sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : -1));
      return players;
    } catch (error) {
      return rejectWithValue("Failed to fetch players");
    }
  }
);

// Async Thunk to Get Player by ID from Firestore
export const fetchPlayerById = createAsyncThunk(
  "players/fetchPlayerById",
  async (id: string, { rejectWithValue }) => {
    try {
      const docRef = doc(db, "players", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() } as Player;
      } else {
        throw new Error("Player not found");
      }
    } catch (error) {
      return rejectWithValue("Failed to fetch player");
    }
  }
);

// Async Thunk to Update All Player IDs to Running Numbers
export const updatePlayerIdsToRunningNumbers = createAsyncThunk(
  "players/updatePlayerIdsToRunningNumbers",
  async (_, { rejectWithValue }) => {
    try {
      const playersRef = collection(db, "players");
      const playersSnapshot = await getDocs(playersRef);

      const players = playersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort players by current ID (or another criterion)
      players.sort((a, b) => (a.id > b.id ? 1 : -1));

      let runningNumber = 1;

      for (const player of players) {
        const newId = runningNumber.toString();
        const playerRef = doc(db, "players", player.id);

        // Update the player's ID and move the document
        const newPlayerRef = doc(db, "players", newId);
        await setDoc(newPlayerRef, { ...player, id: newId });
        await deleteDoc(playerRef);

        runningNumber++;
      }

      message.success("Player IDs updated to running numbers.");
      return true;
    } catch (error) {
      message.error("Failed to update player IDs to running numbers.");
      return rejectWithValue("Failed to update player IDs");
    }
  }
);

// Async Thunk to Fetch Players in Group first round from Firestore
export const fetchPlayersInGroupFirstRound = createAsyncThunk(
  "players/fetchPlayersInGroupFirstRound",
  async (groupId: string, { rejectWithValue }) => {
    try {
      const playersRef = collection(db, "players");
      const q = query(
        playersRef,
        where("isDeleted", "==", false),
        where("firstRoundGroupId", "==", groupId)
      );
      const querySnapshot = await getDocs(q);
      const players: Player[] = [];
      querySnapshot.forEach((doc) => {
        players.push({ id: doc.id, ...doc.data() } as Player);
      });
      return players;
    } catch (error) {
      return rejectWithValue("Failed to fetch players in group");
    }
  }
);

// Async Thunk to Fetch Players in Group knockout round from Firestore
export const fetchPlayersInGroupKnockoutRound = createAsyncThunk(
  "players/fetchPlayersInGroupKnockoutRound",
  async (groupId: string, { rejectWithValue }) => {
    try {
      const playersRef = collection(db, "players");
      const q = query(
        playersRef,
        where("isDeleted", "==", false),
        where("knockoutRoundGroupId", "==", groupId)
      );
      const querySnapshot = await getDocs(q);
      const players: Player[] = [];
      querySnapshot.forEach((doc) => {
        players.push({ id: doc.id, ...doc.data() } as Player);
      });
      return players;
    } catch (error) {
      return rejectWithValue("Failed to fetch players in group");
    }
  }
);

// Async Thunk to Fetch Players in Group semi-final round from Firestore
export const fetchPlayersInGroupSemiFinalRound = createAsyncThunk(
  "players/fetchPlayersInGroupSemiFinalRound",
  async (groupId: string, { rejectWithValue }) => {
    try {
      const playersRef = collection(db, "players");
      const q = query(
        playersRef,
        where("isDeleted", "==", false),
        where("semi-finalRoundGroupId", "==", groupId)
      );
      const querySnapshot = await getDocs(q);
      const players: Player[] = [];
      querySnapshot.forEach((doc) => {
        players.push({ id: doc.id, ...doc.data() } as Player);
      });
      return players;
    } catch (error) {
      return rejectWithValue("Failed to fetch players in group");
    }
  }
);

// Async Thunk to Fetch Players in Group final round from Firestore
export const fetchPlayersInGroupFinalRound = createAsyncThunk(
  "players/fetchPlayersInGroupFinalRound",
  async (groupId: string, { rejectWithValue }) => {
    try {
      const playersRef = collection(db, "players");
      const q = query(
        playersRef,
        where("isDeleted", "==", false),
        where("finalRoundGroupId", "==", groupId)
      );
      const querySnapshot = await getDocs(q);
      const players: Player[] = [];
      querySnapshot.forEach((doc) => {
        players.push({ id: doc.id, ...doc.data() } as Player);
      });

      return players;
    } catch (error) {
      return rejectWithValue("Failed to fetch players in group");
    }
  }
);

// Player Slice
const playerSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    clearPlayer: (state) => {
      state.player = null;
    },
    clearPlayers: (state) => {
      state.players = [];
    },
  },
  extraReducers: (builder) => {
    // Add Player
    builder.addCase(addPlayer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      addPlayer.fulfilled,
      (state, action: PayloadAction<Player>) => {
        state.loading = false;
        state.players.push(action.payload);
      }
    );
    builder.addCase(addPlayer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Fetch Players
    builder.addCase(fetchPlayers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchPlayers.fulfilled,
      (state, action: PayloadAction<Player[]>) => {
        state.loading = false;
        state.players = action.payload;
      }
    );
    builder.addCase(fetchPlayers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Update Player
    builder.addCase(updatePlayer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updatePlayer.fulfilled,
      (state, action: PayloadAction<Player>) => {
        state.loading = false;
        const index = state.players.findIndex(
          (p) => p.id === action.payload.id
        );
        if (index !== -1) {
          state.players[index] = action.payload;
        }
      }
    );
    builder.addCase(updatePlayer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Delete Player
    builder.addCase(deletePlayer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      deletePlayer.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.players = state.players.filter((p) => p.id !== action.payload);
      }
    );
    builder.addCase(deletePlayer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Fetch Player by ID
    builder.addCase(fetchPlayerById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchPlayerById.fulfilled,
      (state, action: PayloadAction<Player>) => {
        state.loading = false;
        state.player = action.payload;
      }
    );
    builder.addCase(fetchPlayerById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Update Player IDs to Running Numbers
    builder.addCase(updatePlayerIdsToRunningNumbers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePlayerIdsToRunningNumbers.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      updatePlayerIdsToRunningNumbers.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      }
    );

    // Fetch Players in Group First Round
    builder.addCase(fetchPlayersInGroupFirstRound.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchPlayersInGroupFirstRound.fulfilled,
      (state, action: PayloadAction<Player[]>) => {
        state.loading = false;
        state.players = action.payload;
      }
    );
    builder.addCase(fetchPlayersInGroupFirstRound.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Fetch Players in Group Knockout Round
    builder.addCase(fetchPlayersInGroupKnockoutRound.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchPlayersInGroupKnockoutRound.fulfilled,
      (state, action: PayloadAction<Player[]>) => {
        state.loading = false;
        state.players = action.payload;
      }
    );
    builder.addCase(
      fetchPlayersInGroupKnockoutRound.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      }
    );

    // Fetch Players in Group Semi-Final Round
    builder.addCase(fetchPlayersInGroupSemiFinalRound.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchPlayersInGroupSemiFinalRound.fulfilled,
      (state, action: PayloadAction<Player[]>) => {
        state.loading = false;
        state.players = action.payload;
      }
    );
    builder.addCase(
      fetchPlayersInGroupSemiFinalRound.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      }
    );

    // Fetch Players in Group Final Round
    builder.addCase(fetchPlayersInGroupFinalRound.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchPlayersInGroupFinalRound.fulfilled,
      (state, action: PayloadAction<Player[]>) => {
        state.loading = false;
        state.players = action.payload;
      }
    );
    builder.addCase(fetchPlayersInGroupFinalRound.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default playerSlice.reducer;

export const { clearPlayer, clearPlayers } = playerSlice.actions;
