import { collection, writeBatch, getDocs } from "firebase/firestore";
import { db } from "../firebase";

export const clearData = async () => {
  try {
    // References to collections
    const counterRef = collection(db, "counters");
    const gameRef = collection(db, "games");
    const shoeRef = collection(db, "shoes");
    const userBetStatByShoeRef = collection(db, "userShoeBetStats");
    const userBetTransactionRef = collection(db, "userBetTransactions");

    // List of collection references to be cleared
    const collections = [
      counterRef,
      gameRef,
      shoeRef,
      userBetStatByShoeRef,
      userBetTransactionRef,
    ];

    // Iterate through each collection reference
    for (const collectionRef of collections) {
      // Get all documents in the collection
      const snapshot = await getDocs(collectionRef);

      // If there are no documents, continue to the next collection
      if (snapshot.empty) {
        continue;
      }

      // Delete documents in batches
      const batch = writeBatch(db);
      snapshot.docs.forEach((doc) => {
        if (
          !(collectionRef.path.includes("counters") && doc.id === "players")
        ) {
          batch.delete(doc.ref);
        }
      });

      // Commit the batch
      await batch.commit();
    }

    console.log("All collections cleared successfully.");
  } catch (error) {
    console.error("Error clearing collections:", error);
  }
};
