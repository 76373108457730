import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  doc,
  getDocs,
  collection,
  runTransaction,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Group } from "../../types";

// Initial State
interface GroupState {
  groups: Group[];
  group: Group | null;
  loading: boolean;
  error: string | null;
}

const initialState: GroupState = {
  groups: [],
  group: null,
  loading: false,
  error: null,
};

// Async Thunks fetch all groups
export const fetchAllGroups = createAsyncThunk(
  "groups/fetchAllGroups",
  async (_, { rejectWithValue }) => {
    try {
      const querySnapshot = await getDocs(collection(db, "groups"));
      const groups: Group[] = [];
      querySnapshot.forEach((doc) => {
        const groupData = doc.data();
        if (!groupData.isDeleted) {
          groups.push({ id: doc.id, ...groupData } as Group);
        }
      });
      return groups;
    } catch (error) {
      return rejectWithValue("Failed to fetch groups");
    }
  }
);

// Fetch Groups by Round
export const fetchGroups = createAsyncThunk(
  "groups/fetchGroups",
  async (
    round: "first" | "knockout" | "semi-final" | "final",
    { rejectWithValue }
  ) => {
    try {
      const querySnapshot = await getDocs(collection(db, "groups"));
      const groups: Group[] = [];
      querySnapshot.forEach((doc) => {
        const groupData = doc.data();
        if (groupData.round === round && !groupData.isDeleted) {
          groups.push({ id: doc.id, ...groupData } as Group);
        }
      });
      return groups;
    } catch (error) {
      return rejectWithValue("Failed to fetch groups");
    }
  }
);

// Update Group Players (Remove all current players and add new ones)
export const updateGroupPlayers = createAsyncThunk(
  "groups/updateGroupPlayers",
  async (
    {
      groupId,
      newPlayerIds,
      round,
    }: {
      groupId: string;
      newPlayerIds: string[];
      round: "first" | "knockout" | "semi-final" | "final";
    },
    { rejectWithValue }
  ) => {
    try {
      const groupRef = doc(db, "groups", groupId);

      await runTransaction(db, async (transaction) => {
        const groupDoc = await transaction.get(groupRef);
        if (!groupDoc.exists()) {
          throw new Error("Group not found");
        }

        const currentGroup = groupDoc.data();

        // Remove all existing players from the group
        const currentPlayerIds = currentGroup.players || [];
        for (const playerId of currentPlayerIds) {
          const playerRef = doc(db, "players", playerId);
          const roundField = `${round}RoundGroupId`;
          transaction.update(playerRef, { [roundField]: null });
        }

        // Add new players to the group
        for (const playerId of newPlayerIds) {
          const playerRef = doc(db, "players", playerId);
          const roundField = `${round}RoundGroupId`;
          transaction.update(playerRef, { [roundField]: groupId });
        }

        // Update the group's players array
        transaction.update(groupRef, { players: newPlayerIds });
      });

      return { groupId, newPlayerIds };
    } catch (error) {
      console.error("Error updating group players:", error);
      return rejectWithValue("Failed to update group players");
    }
  }
);

// Remove Player from Group
export const removePlayerFromGroup = createAsyncThunk(
  "groups/removePlayerFromGroup",
  async (
    {
      groupId,
      playerId,
      round,
    }: {
      groupId: string;
      playerId: string;
      round: "first" | "knockout" | "semi-final" | "final";
    },
    { rejectWithValue }
  ) => {
    try {
      const groupRef = doc(db, "groups", groupId);
      const playerRef = doc(db, "players", playerId);

      const roundField = `${round}RoundGroupId`; // Field to be cleared (e.g., firstRoundGroupId)

      await runTransaction(db, async (transaction) => {
        // Remove player from group's players array
        transaction.update(groupRef, {
          players: arrayRemove(playerId),
        });

        // Clear group ID in the player's document
        transaction.update(playerRef, {
          [roundField]: null,
        });
      });

      return { groupId, playerId };
    } catch (error) {
      return rejectWithValue("Failed to remove player from group");
    }
  }
);

// fetch group by id
export const fetchGroupById = createAsyncThunk(
  "groups/fetchGroupById",
  async (groupId: string, { rejectWithValue }) => {
    try {
      const groupRef = doc(db, "groups", groupId);
      const groupDoc = await getDoc(groupRef);
      if (!groupDoc.exists()) {
        throw new Error("Group not found");
      }

      return { id: groupDoc.id, ...groupDoc.data() } as Group;
    } catch (error) {
      return rejectWithValue("Failed to fetch group");
    }
  }
);

// Group Slice
const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    clearState: (state) => {
      state.groups = [];
      state.group = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Fetch Groups
    builder.addCase(fetchGroups.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchGroups.fulfilled,
      (state, action: PayloadAction<Group[]>) => {
        state.loading = false;
        state.groups = action.payload;
      }
    );
    builder.addCase(fetchGroups.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Update Group Players
    builder.addCase(updateGroupPlayers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateGroupPlayers.fulfilled, (state, action) => {
      state.loading = false;
      const { groupId, newPlayerIds } = action.payload;

      const group = state.groups.find((g) => g.id === groupId);
      if (group) {
        group.players = newPlayerIds;
      }
    });
    builder.addCase(updateGroupPlayers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Remove Player from Group
    builder.addCase(removePlayerFromGroup.fulfilled, (state, action) => {
      const { groupId, playerId } = action.payload;
      const group = state.groups.find((group) => group.id === groupId);
      if (group) {
        group.players = group.players.filter((id) => id !== playerId);
      }
    });
    builder.addCase(removePlayerFromGroup.rejected, (state, action) => {
      state.error = action.payload as string;
    });

    // Fetch Group by ID
    builder.addCase(fetchGroupById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchGroupById.fulfilled,
      (state, action: PayloadAction<Group>) => {
        state.loading = false;
        state.group = action.payload;
      }
    );
    builder.addCase(fetchGroupById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Fetch All Groups
    builder.addCase(fetchAllGroups.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchAllGroups.fulfilled,
      (state, action: PayloadAction<Group[]>) => {
        state.loading = false;
        state.groups = action.payload;
      }
    );
    builder.addCase(fetchAllGroups.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default groupSlice.reducer;

export const { clearState } = groupSlice.actions;
