import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDocs,
  query,
  runTransaction,
  where,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Shoe, Game } from "../../types";

// Initial State
interface RoundState {
  shoes: Shoe[];
  shoe: Shoe | null;
  games: Game[];
  game: Game | null;
  loading: boolean;
  error: string | null;
}

const initialState: RoundState = {
  shoes: [],
  shoe: null,
  games: [],
  game: null,
  loading: false,
  error: null,
};

// Function to create a shoe for a group ensuring only one is running at a time
export const createShoe = createAsyncThunk(
  "rounds/createShoe",
  async (
    groupId: string,
    { rejectWithValue }: { rejectWithValue: (value: string) => void }
  ) => {
    try {
      const counterRef = doc(db, "counters", `shoes-${groupId}`); // Use groupId to create a unique counter
      const shoeCollectionRef = collection(db, "shoes");
      const lockRef = doc(db, "locks", `shoe-lock-${groupId}`); // Lock document reference

      // Use Firestore transaction to ensure atomicity
      const newShoe = await runTransaction(db, async (transaction) => {
        // Perform all reads first
        const lockDoc = await transaction.get(lockRef);
        const runningShoesQuery = await getDocs(
          query(
            collection(db, "shoes"),
            where("groupId", "==", groupId),
            where("status", "==", "running")
          )
        );
        const counterDoc = await transaction.get(counterRef);

        // Now proceed with logic based on the reads

        // If lock exists and is locked, another process is creating a shoe
        if (lockDoc.exists() && lockDoc.data()?.locked) {
          throw new Error(
            "Another process is already creating a shoe for this group."
          );
        }

        // If there is already a running shoe for the group, throw an error
        if (!runningShoesQuery.empty) {
          throw new Error(
            "A shoe with status 'running' already exists for this group"
          );
        }

        // Set the lock to prevent other processes from creating a shoe
        transaction.set(lockRef, {
          locked: true,
          timestamp: Timestamp.now(),
        });

        // Handle the counter for shoe number increment
        let newShoeId = 1;
        if (!counterDoc.exists()) {
          // Initialize the counter document if it doesn't exist
          transaction.set(counterRef, { lastId: newShoeId });
        } else {
          const currentLastId = counterDoc.data().lastId || 0;
          newShoeId = currentLastId + 1;
          // Update the counter document with the new lastId
          transaction.update(counterRef, { lastId: newShoeId });
        }

        // Create a new shoe document with a unique shoe number
        const shoeData: Shoe = {
          groupId,
          shoeNumber: newShoeId,
          games: [],
          status: "running",
          createdAt: Date.now(),
        };

        const shoeDocRef = doc(shoeCollectionRef, `${groupId}-${newShoeId}`);
        transaction.set(shoeDocRef, shoeData);

        // Release the lock
        transaction.delete(lockRef);

        return { ...shoeData, id: shoeDocRef.id };
      });

      return newShoe;
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          `Error creating shoe for group ${groupId}:`,
          error.message,
          error.stack
        );
      } else {
        console.error(`Error creating shoe for group ${groupId}:`, error);
      }
      return rejectWithValue("Failed to create shoe");
    }
  }
);

// Create Game
export const createGame = createAsyncThunk(
  "rounds/createGame",
  async (
    args: { groupId: string; shoeId: string; shoeNumber: number },
    { rejectWithValue }: { rejectWithValue: (value: string) => void }
  ) => {
    try {
      const { shoeId, groupId, shoeNumber } = args;
      const counterRef = doc(db, "counters", `games-${shoeId}`);
      const gameCollectionRef = collection(db, "games");
      const lockRef = doc(db, "locks", `game-lock-${shoeId}`);

      // Use Firestore transaction to ensure atomicity
      const newGame = await runTransaction(db, async (transaction) => {
        // Perform all reads first
        const lockDoc = await transaction.get(lockRef);
        const runningGamesQuery = await getDocs(
          query(
            collection(db, "games"),
            where("shoeId", "==", shoeId),
            where("status", "==", "running")
          )
        );
        const counterDoc = await transaction.get(counterRef);

        // If lock exists and is locked, another process is creating a game
        if (lockDoc.exists() && lockDoc.data()?.locked) {
          throw new Error(
            "Another process is already creating a game for this shoe."
          );
        }

        // Check if there is already a running game for the shoe
        if (!runningGamesQuery.empty) {
          throw new Error(
            "A game with status 'running' already exists for this shoe"
          );
        }

        // Now perform writes
        transaction.set(lockRef, {
          locked: true,
          timestamp: Timestamp.now(),
        });

        // Handle the counter for game number increment
        let newGameId = 1;
        if (!counterDoc.exists()) {
          transaction.set(counterRef, { lastId: newGameId });
        } else {
          const currentLastId = counterDoc.data().lastId || 0;
          newGameId = currentLastId + 1;
          transaction.update(counterRef, { lastId: newGameId });
        }

        // Create a new game document
        const gameData: Game = {
          shoeId,
          groupId,
          shoeNumber,
          gameNumber: newGameId,
          status: "running",
          createdAt: Date.now(),
        };

        const gameDocRef = doc(gameCollectionRef, `${shoeId}-${newGameId}`);
        transaction.set(gameDocRef, gameData);

        // Release the lock
        transaction.delete(lockRef);

        return { ...gameData, id: gameDocRef.id };
      });

      return newGame;
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          `Error creating game for shoe ${args.shoeId}:`,
          error.message,
          error.stack
        );
      } else {
        console.error(`Error creating game for shoe ${args.shoeId}:`, error);
      }
      return rejectWithValue("Failed to create game");
    }
  }
);

// Find running shoe or create a new one
export const findOrCreateShoe = createAsyncThunk(
  "rounds/findOrCreateShoe",
  async (groupId: string, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const runningShoesQuery = await getDocs(
        query(
          collection(db, "shoes"),
          where("groupId", "==", groupId),
          where("status", "==", "running")
        )
      );

      if (!runningShoesQuery.empty) {
        const shoe = runningShoesQuery.docs[0];
        return { ...shoe.data(), id: shoe.id } as Shoe;
      }

      const newShoe = await dispatch(createShoe(groupId)).unwrap();
      return newShoe;
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          `Error finding or creating shoe for group ${groupId}:`,
          error.message,
          error.stack
        );
      } else {
        console.error(
          `Error finding or creating shoe for group ${groupId}:`,
          error
        );
      }
      return rejectWithValue("Failed to find or create shoe");
    }
  }
);

// Find running game or create a new one
export const findOrCreateGame = createAsyncThunk(
  "rounds/findOrCreateGame",
  async (
    args: { groupId: string; shoeId: string; shoeNumber: number },
    thunkAPI
  ) => {
    const { shoeId } = args;
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const { shoeId } = args;
      const runningGamesQuery = await getDocs(
        query(
          collection(db, "games"),
          where("shoeId", "==", shoeId),
          where("status", "==", "running")
        )
      );

      if (!runningGamesQuery.empty) {
        const game = runningGamesQuery.docs[0];
        return { ...game.data(), id: game.id } as Game;
      }

      const newGame = await dispatch(createGame(args)).unwrap();
      return newGame;
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          `Error finding or creating game for shoe ${shoeId}:`,
          error.message,
          error.stack
        );
      } else {
        console.error(
          `Error finding or creating game for shoe ${shoeId}:`,
          error
        );
      }
      return rejectWithValue("Failed to find or create game");
    }
  }
);

// Slice
const RecordSlice = createSlice({
  name: "record",
  initialState,
  reducers: {
    clearState: (state) => {
      state.shoes = [];
      state.shoe = null;
      state.games = [];
      state.game = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Create Shoe
    builder.addCase(createShoe.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      createShoe.fulfilled,
      (state, action: PayloadAction<Shoe | void>) => {
        state.loading = false;
        if (action.payload) {
          state.shoes.push(action.payload);
          state.shoe = action.payload; // Set the shoe in state directly
        }
      }
    );
    builder.addCase(createShoe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Create Game
    builder.addCase(createGame.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      createGame.fulfilled,
      (state, action: PayloadAction<Game | undefined | void>) => {
        state.loading = false;
        if (action.payload) {
          state.games.push(action.payload);
          state.game = action.payload; // Set the game in state directly
        }
      }
    );
    builder.addCase(createGame.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Find or Create Shoe
    builder.addCase(findOrCreateShoe.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      findOrCreateShoe.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.shoe = action.payload;
      }
    );
    builder.addCase(findOrCreateShoe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Find or Create Game
    builder.addCase(findOrCreateGame.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      findOrCreateGame.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.game = action.payload;
      }
    );
    builder.addCase(findOrCreateGame.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default RecordSlice.reducer;

export const { clearState } = RecordSlice.actions;
