// logger.ts
import * as Sentry from "@sentry/react";

enum LogLevel {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

class Logger {
  private static user: any = null;

  static initialize() {}

  private static log(
    level: LogLevel,
    message: string,
    ...optionalParams: any[]
  ) {
    const timestamp = new Date().toISOString();
    switch (level) {
      case LogLevel.INFO:
        console.info(`[INFO] [${timestamp}] ${message}`, ...optionalParams);
        break;
      case LogLevel.WARN:
        console.warn(`[WARN] [${timestamp}] ${message}`, ...optionalParams);
        break;
      case LogLevel.ERROR:
        console.error(`[ERROR] [${timestamp}] ${message}`, ...optionalParams);
        Sentry.captureException(new Error(message), {
          extra: { optionalParams, user: this.user },
        });
        break;
    }
  }

  static info(message: string, ...optionalParams: any[]) {
    this.log(LogLevel.INFO, message, ...optionalParams);
  }

  static warn(message: string, ...optionalParams: any[]) {
    this.log(LogLevel.WARN, message, ...optionalParams);
  }

  static error(message: string, ...optionalParams: any[]) {
    this.log(LogLevel.ERROR, message, ...optionalParams);
  }
}

export default Logger;
