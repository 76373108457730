import { initializeApp } from "firebase/app";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAwmgTG9Wlya4PA-a2CuExr8FHuVfjjzxY",
  authDomain: "b-world-cup.firebaseapp.com",
  projectId: "b-world-cup",
  storageBucket: "b-world-cup.firebasestorage.app",
  messagingSenderId: "98319197930",
  appId: "1:98319197930:web:a8f67300f83071dbf35993",
  measurementId: "G-HNSDR9874R",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Analytics
export const analytics = getAnalytics(app);
export const logFirebaseEvent = logEvent;

// Initialize Firestore
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache(
    /*settings*/ { tabManager: persistentMultipleTabManager() }
  ),
});
