import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  Route,
  Link,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import {
  Button,
  Layout,
  Menu,
  message,
  Popconfirm,
  Result,
  Space,
  Spin,
  Typography,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import "./App.css";
import "antd/dist/reset.css";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { SentryRoutes } from ".";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./app/store";
import { setCurrentUser } from "./app/store/userSlice";
import { clearData } from "./services/firebase";

// Lazy-loaded components
const components = {
  ShowRecordGroupPage: lazy(
    () => import("./features/record/ShowRecordGroupPage")
  ),
  BettingTablePage: lazy(() => import("./features/record/BettingTablePage")),
  AnalyticsTracker: lazy(() => import("./features/global/AnalyticsTracker")),
  PlayerPage: lazy(() => import("./features/players/PlayerPage")),
  AddPlayerPage: lazy(() => import("./features/players/AddPlayerPage")),
  EditPlayerPage: lazy(() => import("./features/players/EditPlayerPage")),
  FirstRoundGroupPage: lazy(
    () => import("./features/groups/FirstRoundGroupPage")
  ),
  KnockOutGroupPage: lazy(() => import("./features/groups/KnockOutGroupPage")),
  SemiFinalGroupPage: lazy(
    () => import("./features/groups/SemiFinalGroupPage")
  ),
  FinalGroupPage: lazy(() => import("./features/groups/FinalGroupPage")),
  LoginPage: lazy(() => import("./features/login/LoginPage")),
  SelectRoundPage: lazy(() => import("./features/record/SelectRoundPage")),
  SelectGroupPage: lazy(() => import("./features/record/SelectGroupPage")),
  ReportBetByShoePage: lazy(() => import("./features/report/ReportByShoePage")),
  PrizePage: lazy(() => import("./features/prizes/PrizePage")),
  ChampionTrophyPage: lazy(
    () => import("./features/prizes/ChampionTrophyPage")
  ),
  MostBetsPlacedPage: lazy(
    () => import("./features/prizes/MostBetsPlacedPage")
  ),
  PerfectRoundPage: lazy(() => import("./features/prizes/PerfectRoundPage")),
  BiggestBetWinnerPage: lazy(
    () => import("./features/prizes/BiggestBetWinnerPage")
  ),
  PlayerWithTheMostChipPage: lazy(
    () => import("./features/prizes/PlayerWithTheMostChipPage")
  ),
  LastChanceHeroPage: lazy(
    () => import("./features/prizes/LastChanceHeroPage")
  ),
  FirstBetVictoryPage: lazy(
    () => import("./features/prizes/FirstBetVictoryPage")
  ),
  NotfoundPage: lazy(() => import("./features/global/NotfoundPage")),
};

const { Content, Sider } = Layout;

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);

  const { currentUser } = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const firstPath = location.pathname.split("/")[1];
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(
          setCurrentUser({
            id: user.uid,
            email: user.email || "",
          })
        );

        // Set user information in Sentry
        Sentry.setUser({
          id: user.uid,
          username: user.displayName || "",
          email: user.email || "",
        });
      } else {
        setCurrentUser(null);
        Sentry.setUser(null);
        navigate("/login");
      }
    });

    return () => {
      unsubscribe();
      setCurrentUser(null);
    };
  }, [dispatch, auth, navigate]);

  const handleClearData = async () => {
    await clearData();
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      message.success("Logged out successfully.");
      window.location.reload();
      // navigate("/login");
    } catch (error) {
      message.error("Failed to log out.");
    }
  };

  const defaultMenu = [
    { key: "record", label: <Link to="/record">บันทึกการแข่งขัน</Link> },
    { key: "players", label: <Link to="/players">ผู้แข่งขัน</Link> },
    { key: "group-stage", label: <Link to="/group-stage">รอบแบ่งกลุ่ม</Link> },
    { key: "knockout", label: <Link to="/knockout">รอบ Knockout</Link> },
    { key: "semi-final", label: <Link to="/semi-final">รอบ Semi-Final</Link> },
    { key: "final", label: <Link to="/final">รอบ Final</Link> },
    { key: "reports", label: <Link to="/reports">รายงานการแข่งขัน</Link> },
    { key: "prizes", label: <Link to="/prizes">ผลรางวัล</Link> },
    { key: "", label: "" },
    {
      key: "logout",
      label: (
        <Button
          variant="link"
          color="danger"
          style={{ color: "red", cursor: "pointer", width: "100%" }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      ),
    },

    {
      key: "cleardata",
      label: (
        <Popconfirm
          title="Are you sure to clear all data?"
          onConfirm={handleClearData}
          okText="Yes"
          cancelText="No"
        >
          <Button
            variant="link"
            color="danger"
            style={{ color: "red", cursor: "pointer", width: "100%" }}
            onClick={handleClearData}
          >
            Clear Data
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const checkMenuPermission = (path: string) => {
    let allowedPaths = [
      "record",
      "players",
      "group-stage",
      "knockout",
      "semi-final",
      "final",
      "reports",
      "prizes",
      "",
      "logout",
      "cleardata",
    ];

    if (auth.currentUser?.email?.startsWith("staff")) {
      allowedPaths = ["record", "logout"];
    }

    return allowedPaths.includes(path);
  };

  const menu = defaultMenu.filter((item) => checkMenuPermission(item.key));

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Result
          status="error"
          title="Something went wrong"
          subTitle={
            <>
              Sorry, an unexpected error occurred:{" "}
              {(error as Error)?.message || "Unknown error"}
              <details style={{ whiteSpace: "pre-wrap", marginTop: "10px" }}>
                {(error as Error)?.stack}
                <div>{componentStack}</div>
              </details>
            </>
          }
          extra={
            <Button type="primary" onClick={() => window.location.reload()}>
              Reload
            </Button>
          }
        />
      )}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <Suspense
          fallback={
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            />
          }
        >
          <components.AnalyticsTracker />
          {currentUser && (
            <Sider
              collapsible
              collapsed={collapsed}
              onCollapse={setCollapsed}
              style={{
                backgroundColor: "rgb(255, 255, 255)",
              }}
            >
              <Space
                align="center"
                style={{
                  width: "100%",
                  padding: "10px",
                  paddingLeft: "1.5rem",
                  wordBreak: "break-word",
                }}
              >
                <Typography.Text>
                  user: {currentUser?.email?.replace("@betworld.io", "")}
                </Typography.Text>
              </Space>
              <hr />
              <Menu
                mode="inline"
                defaultSelectedKeys={[firstPath]}
                items={menu}
              ></Menu>

              <Button
                type="default"
                onClick={() => window.location.reload()}
                style={{
                  margin: "16px",
                  position: "fixed",
                  bottom: "50px",
                }}
              >
                <ReloadOutlined />
              </Button>
            </Sider>
          )}

          <Layout>
            <Content
              style={{
                margin: "5px",
                padding: "5px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  />
                }
              >
                <SentryRoutes>
                  {/* Redirect root to /record */}
                  <Route path="/" element={<Navigate to="/record" replace />} />

                  {!currentUser && (
                    <Route path="/login" element={<components.LoginPage />} />
                  )}
                  {currentUser && (
                    <>
                      {checkMenuPermission("record") && (
                        <>
                          <Route
                            path="/record"
                            element={<components.SelectRoundPage />}
                          />
                          <Route
                            path="/record/:round"
                            element={<components.SelectGroupPage />}
                          />
                          <Route
                            path="/record/:round/group/:group"
                            element={<components.ShowRecordGroupPage />}
                          />
                          <Route
                            path="/record/:round/group/:group/betting"
                            element={<components.BettingTablePage />}
                          />
                        </>
                      )}

                      {checkMenuPermission("players") && (
                        <>
                          <Route
                            path="/players"
                            element={<components.PlayerPage />}
                          />
                          <Route
                            path="/players/add"
                            element={<components.AddPlayerPage />}
                          />
                          <Route
                            path="/players/edit/:id"
                            element={<components.EditPlayerPage />}
                          />
                        </>
                      )}

                      {checkMenuPermission("group-stage") && (
                        <Route
                          path="/group-stage"
                          element={<components.FirstRoundGroupPage />}
                        />
                      )}

                      {checkMenuPermission("knockout") && (
                        <Route
                          path="/knockout"
                          element={<components.KnockOutGroupPage />}
                        />
                      )}

                      {checkMenuPermission("semi-final") && (
                        <Route
                          path="/semi-final"
                          element={<components.SemiFinalGroupPage />}
                        />
                      )}

                      {checkMenuPermission("final") && (
                        <Route
                          path="/final"
                          element={<components.FinalGroupPage />}
                        />
                      )}

                      {checkMenuPermission("reports") && (
                        <Route
                          path="/reports"
                          element={<components.ReportBetByShoePage />}
                        />
                      )}

                      {checkMenuPermission("prizes") && (
                        <>
                          <Route
                            path="/prizes"
                            element={<components.PrizePage />}
                          />
                          <Route
                            path="/prizes/champion"
                            element={<components.ChampionTrophyPage />}
                          />
                          <Route
                            path="/prizes/most-bets"
                            element={<components.MostBetsPlacedPage />}
                          />
                          <Route
                            path="/prizes/perfect-round"
                            element={<components.PerfectRoundPage />}
                          />
                          <Route
                            path="/prizes/biggest-bet"
                            element={<components.BiggestBetWinnerPage />}
                          />
                          <Route
                            path="/prizes/most-chips"
                            element={<components.PlayerWithTheMostChipPage />}
                          />
                          <Route
                            path="/prizes/last-chance"
                            element={<components.LastChanceHeroPage />}
                          />
                          <Route
                            path="/prizes/first-bet"
                            element={<components.FirstBetVictoryPage />}
                          />
                        </>
                      )}

                      <Route path="*" element={<components.NotfoundPage />} />
                    </>
                  )}
                </SentryRoutes>
              </Suspense>
            </Content>
          </Layout>
        </Suspense>
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default App;
